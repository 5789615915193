#whoarewe-content-right {
    margin-bottom: 50px;
}

.whoarewe-menu {
    padding: 22px 20px 22px 20px;
    background-color: @tsf-light-grey;
    border-radius: 4px;
}

.whoarewe-menu ul {
    margin-top: 10px;
}

#whoarewe-partners {
    background-color: #D8D8D8;
}

.whoarewe-partners-box {
    margin-right: 15px;
    margin-left: 15px;
    margin-bottom: 30px;
    background-color: #FFFFFF;
}
