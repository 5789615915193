#tsfi-partnerships .card-body-category span {
    font-size: 14px;
    font-weight: 600;
    line-height: 18px;
    padding: 5px;
    margin-bottom: 14px;
    background-color:@tsfi-blue;
    color:@tsfi-white;
}

#tsfi-partnerships .card-body-desc {
    color: #212121;
    font-size: 17px;
    line-height: 22px;
    margin-bottom: 10px;

}

#tsfi-join-us .card-body-category span {
    font-size: 14px;
    font-weight: 600;
    line-height: 18px;
    padding: 5px;
    margin-bottom: 14px;
    background-color:@tsfi-orange;
    color:@tsfi-white;
}

#tsfi-join-us .card-body-desc {
    color: #212121;
    font-size: 17px;
    line-height: 22px;
    margin-bottom: 10px;

}

#partnerships-content iframe {
    width: 300px;
    height: 168px;
}

@media (min-width: 576px) {
    #partnerships-content iframe {
        width: 500px;
        height: 280px;
    }
}

@media (min-width: 768px) {
}

@media (min-width: 992px) {
    #partnerships-content iframe {
        width: 800px;
        height: 450px;
    }
}

@media (min-width: 1200px) {
}

