.heading-ngo{
    background-color:@tsfi-blue;
    color:@tsfi-white;
    padding:5px;
}

.current-missions-title{
    background-image: url('@{current-missions-img-url}');
    color:@tsfi-white;
    padding:15px;
}

.leading-intervention img {
    width: 140px;
}

.leading-intervention a {
    margin: 10px 10px 10px 10px;
}

.leading-intervention .h1 {
    color: white; 
    text-align: center; 
    font-size: 20px;
}

.leading-intervention object {
    height:50px;
}

@media (min-width: 576px) {
}

@media (min-width: 768px) {
}

@media (min-width: 992px) {
    .leading-intervention img {
        width: 170px;
    }

    .leading-intervention .h1 {
        color: white; 
        text-align: center; 
        font-size: 30px;
    }

    .leading-intervention object {
        height:50px;
    }
}

@media (min-width: 1200px) {
    .leading_articles-content {
        height: 450px;
        min-height: 450px;
        max-height: 450px;
    }
/*
    .brief-block {
        height: 449px;
        min-height: 449px;
        max-height: 449px;
    }
*/
    .leading-intervention img {
        width: 180px;
    }

    .leading-intervention .h1 {
        color: white; 
        text-align: center; 
        font-size: 25px;
    }

    .leading-intervention object {
        height:60px;
    }
}

@media (min-width: 1600px) {
    .leading_articles-content {
        height: 650px;
        min-height: 650px;
        max-height: 650px;
    }
/*
    .brief-block {
        height: 654px;
        min-height: 654px;
        max-height: 654px;
    }
*/
    .leading-intervention img {
        width: 209px;
    }
    .leading-intervention .h1 {
        color: white; 
        text-align: center; 
        font-size: 35px;
    }

    .leading-intervention object {
        height:85px;
    }
}
