.brief-block{
    background-color:@tsfi-white;
}

.brief-picto{
    width:14px;
    margin:2px
}
@media (min-width: 576px) {
}

@media (min-width: 768px) {
}

@media (min-width: 992px) {
}

@media (min-width: 1200px) {
}
