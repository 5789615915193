.heading-image {
    width: 100%;
}

h1, h2,
.h1, .h2 {
    font-family:"Bebas Neue";
}

h3, .h3 {
    font-family:"Roboto";
}

h1, .h1 {
    font-size:30px;
}

h2, .h2 {
    font-size:25px;
}

h3, .h3 {
    font-size:22px;
}

#heading h1 {
    color:@tsfi-white;
}

.heading-disaster-response {
    background-color:@tsfi-red;
}

.heading-capacity-building {
    background-color:@tsfi-light-green;
}

.heading-education {
    background-color:@tsfi-orange;
}

.heading-digital-divide{
    background-color:@tsfi-purple;
}

.heading-protection{
    background-color:@tsfi-dark-green;
}

@media (min-width: 576px) {
    h1, .h1 {
        font-size:40px;
    }

    h2, .h2 {
        font-size:35px;
    }

    h3, .h3 {
        font-size:22px;
    }
}

@media (min-width: 768px) {
    h1, .h1 {
        font-size:50px;
    }

    h2, .h2 {
        font-size:35px;
    }

    h3, .h3 {
        font-size:22px;
    }
}

@media (min-width: 992px) {
}

@media (min-width: 1200px) {
}
