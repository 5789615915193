/*** Fields of interventions ***/

.fields_of_interventions-desc {
	font-size: 12px;
}

.fields_of_interventions {
	&.disaster-response {
		color: @tsfi-red;
	}
	&.capacity-building {
		color: @tsfi-light-green;
	}
	&.education {
		color: @tsfi-orange;
	}
	&.digital-divide {
		color: @tsfi-purple;
	}
	&.protection{
		color: @tsfi-dark-green;
	}
}

/*** Field of interventions ***/

/* Titles */

.tsfi-heading-title-foi h1 {
	display: flex;
	align-items: center;
	text-align: center;
}

/* Content */
#intervention-content-left {
	margin-bottom: 50px;
}

#intervention-content-right {
	background-color: @tsf-light-grey;
	border-radius: 4px;
}

.intervention-content-right div.h3 {
	margin: 22px 0 20px 0;
}

@media (min-width: 50px) {
	.intervention-picto {
		height: 100px;
		padding: 10px;
		margin: 20px;
	}
}

@media (min-width: 768px) {
	.intervention-picto {
		padding: 10px;
		margin: 20px;
	}
}

@media (min-width: 992px) {
}

@media (min-width: 1200px) {
	.intervention-picto {
	}

	#intervention-content-left {
		padding-right: 50px;
		margin-bottom: 0px;
	}
}

