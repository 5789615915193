

.navbar-tsf {
    background-color: white !important;
}

.navbar-tsf .plone-navbar-toggle {
    background-color: @tsfi-blue;

}

.header-row2 a,
#portal-languageselector {
    color: #555 !important;
    font-family:"Bebas Neue";
    font-size:20px !important;
}


#portal-logo img {
    height: 105px;
}

.plone-navbar-nav > .selected > a, 
.plone-navbar-nav > .selected > a:hover, 
.plone-navbar-nav > .selected > a:focus {
/*    background-color: #f3f3f3;*/
    background-color: white;
    color: #218fbe !important;
}

.plone-nav > li > a:hover,
.plone-nav > li > a:focus {
/*    background-color: #f3f3f3;*/
    background-color: white;
    color: #218fbe !important;
}

//*// searchbox //*// 
#portal-header #portal-searchbox {
    position: relative;
    margin-top: 5px;
    margin-bottom: -5px;
    padding-top: 0;
    clear: none;
    float: none;
    white-space: nowrap;
}

#portal-header #portal-searchbox [type="text"] {
    border-radius: 4px;
    width: 100%;
    display: inline-block;
  @media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (orientation: portrait){
    width: 180px;
    }
}

#searchGadget::placeholder { 
   font-family:fontAwesome; font-size:18px; color:#cccccc; 
}

#search {
  @media (max-width: 767px) {
    margin-top: -55px;
  }
}

/* fix size responsive */
.pat-livesearch {
    min-width: 100px;
    position: relative;

}


/*** lang ***/
#portal-header #portal-languageselector {
    float: left;
    clear: both;
    display: inline-block;
    margin-right: 0;
    padding-top: 5px;
    padding-left: 15px;
}


#portal-languageselector a:hover,
#portal-languageselector .currentLanguage a {
    background-color: white;
    color: #218fbe !important;
    font-weight: normal;
}

/*** Donate ***/
.donate_btn a {
    color: white !important;
    background-color: @tsfi-red;
    padding: 2px 8px;
}

.donate_btn a:hover {
    /*background-color: darken(@tsfi-red, 10%);*/
    background-color: @tsfi-blue;
}


@media (min-width: 576px) {
}

@media (min-width: 768px) {
}

/* center navbar content */
@media (min-width: 768px) {
    .navbar-tsf .plone-navbar-nav {
      display: inline-block;
      float: none;
      vertical-align: top;
    }

    .navbar-tsf .plone-navbar-collapse {
      text-align: center;
    }

    .navbar-tsf .plone-navbar-header {
        width: 100%;
    }
    /* fix size responsive */
    .pat-livesearch {
        min-width: 230px;
        position: relative;

    }
}
/* /center */

@media (min-width: 992px) {
    .header-row2 a,
    #portal-languageselector {
        font-size:22px !important;
    }
    .donate_btn {
        padding: 2px 6px;
    }
}

@media (min-width: 1200px) {
    .header-row2 a,
    #portal-languageselector {
        font-size:24px !important;
    }
}

@media (min-width: 1600px) {
    .header-row2 a,
    #portal-languageselector {
        font-size:28px !important;
    }
    .donate_btn {
        padding: 2px 8px;
    }
}
