/*** Content ***/

#content {
    margin-bottom: 0 !important;
}

h1, .h1 {
    font-family: "Raleway";
    font-size: 28px;
    font-weight: 600;
    color: @tsf-black;
}

h2, .h2 {
    font-family: "Raleway";
    font-size: 24px;
    font-weight: 600;
    color: @tsf-black;
}

.h2-center {
    text-align: center;
    font-family: "Raleway";
    font-size: 24px;
    font-weight: 600;
    color: @tsf-black;
}

h3, .h3 {
    font-family: "Raleway";
    font-size: 20px;
    font-weight: 600;
    color: @tsf-black;
}

h4, .h4 {
    font-family: "Raleway";
    font-size: 17px;
    font-weight: 600;
    color: @tsf-black;
}

.tsfi-paragraph,
.plone-modal-content {
    font-family: "Raleway";
    font-size: 18px;
    font-weight: 400;
    line-height: 27px;
    color: @tsf-black;
}

.tsfi-smallparagraph {
    font-family: "Raleway";
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    color: @tsf-black;
}

.tsfi-secondarytext {
    font-family: "Raleway";
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    color: @tsf-light-black;
}

.tsfi-content {
    margin-top: 50px;
    margin-bottom: 50px;
}

.tsfi-section {
    margin-right: -15px;
    margin-left: -15px;
    padding-top: 50px;
    padding-bottom: 30px
}

.tsfi-section > h2 {
    text-align:center;
    margin-top: 0;
    margin-bottom: 34px;
}

.tsfi-text p {
    text-align: justify;
    text-justify: inter-word;
}

.tsfi-text img,
.tsfi-content img {
  max-width: 100%;
  height: auto;
}

span.fa-quote {
    font-family:fontAwesome;
}

#sconnect-is-installed {
    display:none;
}

/*** tsfi heading ***/
.tsfi-slogan {
    font-family: "Bebas Neue";
    color: @tsf-white;
    background: rgba(0,0,0,0.50);
    padding: 4px 15px 4px 15px;
}

.tsfi-slogan-responsive {
    background-color: @tsf-black;
}

.tsfi-slogan-responsive span {
    font-family: "Bebas Neue";
    color: @tsf-white;
}

#tsfi-heading {
    background-color: @tsf-light-grey;
    padding-top: 34px;
    padding-bottom: 34px;
}

.tsfi-heading-cc {
    text-align: center;
}

.tsfi-heading-categories {
    text-align: center;
    font-weight: bold;
}
.tsfi-heading-countries a {
    color: #FFFFFF;
    font-size: 14px;
    font-weight: 600;
    line-height: 18px;
    padding: 5px;
    font-weight: bold;
}
.tsfi-heading-title {
    text-align: center;
}
.tsfi-heading-title h1 {
    margin-top: 8px;
    margin-bottom: 0;
}
.tsfi-heading-title-first {
    text-align: center;
}
.tsfi-heading-title-first h1 {
    margin: 0;
}
.tsfi-heading-title-only-wpd {
    text-align: center;
}
.tsfi-heading-date {
    padding-top: 9px;
    text-align: center;
    font-weight: bold;
    color: @tsf-light-black;
}
.tsfi-heading-meta {
    padding-top: 8px;
    text-align: center;
    color: @tsf-light-black;
}
.tsfi-heading-desc {
    padding-top: 22px;
    padding-left: 20px;
    padding-right: 20px;
    text-align: center;
    font-size: 20px;
    line-height: 30px;
    color: @tsf-black;
}

.tsfi-heading-desc ul,
.tsfi-heading-desc .ul {
    text-align: left;
}

.tsfi-heading-illustration {
    padding-top: 35px;
}


/*** panel toggle ***/
.tsfi-panel {
    margin-top: 10px;
    margin-bottom: 10px;
    border-radius: 5px;
    padding-bottom: 10px;
}

.tsfi-panel-title {

}
@media (max-width: 992px) {
    /* Arrow */
    a[class*='tsfi-toggle']:after {
        font-family: 'Glyphicons Halflings';
        content: "\e114";
        float: right;
    }
    .tsfi-toggle:after {
        color: white;
    }
    .tsfi-toggle-blue:after {
        color: @tsf-blue;
    }
    .tsfi-toggle-black:after {
        color: @tsf-black;
    }

}
@media (min-width: 992px) {
    a[class*='tsfi-toggle'] {
        cursor: default;
    }
    [id*='tsfi-toggle-content'].collapse {
        display: block;
        height: auto;
    }
}



/*** crop text too long ***/
.tsfi-crop-text-200 {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 200px;
}

.tsfi-crop-text-500 {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 500px;
}

/* leading article */
.tsfi-crop-text-3 {
    overflow: hidden; 
    text-overflow: ellipsis; 
    display: -webkit-box;
/* 
    line-height: 16px;  
    max-height: 32px;      
*/      
    /* The number of lines to be displayed */ 
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical; 
}

/* mission card */
.tsfi-crop-text-4 {
    overflow: hidden; 
    text-overflow: ellipsis; 
    display: -webkit-box;
/* 
    line-height: 16px;  
    max-height: 32px;      
*/      
    /* The number of lines to be displayed */ 
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical; 
}
/*** Margin ***/
.tsfi-nomargin {
    margin: 0 -15px 0 -15px;
}

/*** Link ***/

a {
    color: @tsf-black; 
}

a.card:hover,
a.card:focus {
    text-decoration: none;
    filter: brightness(95%);
}

a.readmore {
    font-size: 14px;
    color: @tsfi-blue;
    font-weight: 600;
}

a.readmore:hover,
a.readmore:focus {
    color: darken(@tsfi-blue, 10%);
    text-decoration: none;
}

/*** Load more ***/

.tsfi-loadmore a {
    display: inline-block;
    font-weight: 400;
    color: #212529;
    text-align: center;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-color: transparent;
    border: 1px solid transparent;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    color: #fff;
    background-color: #337ab7;
    border-color: #337ab7;
    padding: 0.5rem 1rem;
    font-size: 1.25rem;
    line-height: 1.5;
    border-radius: 0.3rem;
    text-decoration: none;
}

.tsfi-loadmore a:hover {
    color: #fff;
    background-color: #0069d9;
    border-color: #0062cc;
}

.tsfi-loadmore a:focus {
    color: #fff;
    background-color: #0069d9;
    border-color: #0062cc;
    box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
}

/* disable icon for external links */
.glyphicon.link-external {
  display: none;
}


/* disable icon for mail */
.glyphicon.link-mailto {
  display: none;
}

/*** Iframe ***/
iframe {
    width: 280px;
    height: 157px;
}

@media (min-width: 576px) {
    iframe {
        width: 420px;
        height: 236px;
    }
}
@media (min-width: 768px) {
    iframe {
        width: 560px;
        height: 314px;
    }
}

/*** fix for glyphicon ***/
ul li i.link-https::after {
    content: none !important;
}
i.glyphicon.link-https {
    display: none !important;
}
ul li i.glyphicon {
    padding: 0;
    margin: 0;
    top: 0;
    display: none;
    line-height: 0;
}

/*** pagination ***/

.mission-pagination .pagination ul {
    text-align: center;
}

.mission-pagination .pagination li {
    display: inline-block;
}

.mission-pagination .pagination li {
    color: black;
    padding: 8px 16px;
    text-decoration: none;
}

.mission-pagination .pagination li.active {
    background-color: @tsfi-blue;
    color: white;
    border-radius: 5px;
}

.mission-pagination .pagination li:hover:not(.active) {
    background-color: @tsfi-grey;
    border-radius: 5px;
}

.mission-pagination .pagination .previous,
.mission-pagination .pagination .next {
    display: none;
}

/*** image ***/
.img-bright {
    filter: brightness(80%);
}

.img-linear-gradient::after {
    display:block;
    background: linear-gradient(90deg,rgba(0,0,0,0), rgba(0,0,0,0) 65% ,rgba(0,0,0,157));
    position: absolute;
    content: '';
    height: 100%;
    width: 100%;
    top: 0;
}

.img-bright-mission {
    filter: brightness(55%);
}
.img-bright-articles {
    filter: brightness(55%);
}
.img-bright-interventions {
    filter: brightness(55%);
}

/*
.tsfi-leading-effect:hover {
    opacity: 0.95;
    filter: alpha(opacity=95);
}
*/
.tsfi-leading-effect:hover img {
    filter: brightness(95%);
}

/*** center iframe for video ***/
p > iframe {
    text-align: center;
}


/*** expertise colors***/
.background-tsfi {
    background-color:@tsfi-blue;
    color:@tsfi-white;
}

.background-disaster-response {
    background-color:@tsfi-red;
    color:@tsfi-white;
}

.background-capacity-building {
    background-color:@tsfi-light-green;
    color:@tsfi-white;
}

.background-education {
    background-color:@tsfi-orange;
    color:@tsfi-white;
}

.background-digital-divide{
    background-color:@tsfi-purple;
    color:@tsfi-white;
}

.background-protection{
    background-color:@tsfi-dark-green;
    color:@tsfi-white;
}

.disaster-response {
    color:@tsfi-red;
}

.capacity-building {
    color:@tsfi-light-green;
}

.education {
    color:@tsfi-orange;
}

.digital-divide{
    color:@tsfi-purple;
}

.protection{
    color:@tsfi-dark-green;
}

/*** img effect ***/
.ih-item {
  position: relative;
  -webkit-transition: all 0.35s ease-in-out;
  -moz-transition: all 0.35s ease-in-out;
  transition: all 0.35s ease-in-out;
}
.ih-item,
.ih-item * {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.ih-item a {
  color: #333;
}
.ih-item a:hover {
  text-decoration: none;
}
.ih-item img {
  width: 100%;
  height: 100%;
}
.ih-item .info {
  width: 100%;
  height: 100%;
}

.ih-item.square {
  position: relative;
/*
  width: 540px;
  height: 136px;
*/
  width: 316px;
  height: 216px;
  border: 8px solid #fff;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.3);
}
.ih-item.square .img {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.ih-item.square.effect10 {
  overflow: hidden;
}
.ih-item.square.effect10 .info {
  -webkit-transition: all 0.35s ease-in-out;
  -moz-transition: all 0.35s ease-in-out;
  transition: all 0.35s ease-in-out;
}

.ih-item.square.effect10 .info {
  background-color: #f3f3f3
}

.ih-item.square.effect10 .img {
  background: #333333;
  visibility: hidden;
  opacity: 0;
  -webkit-transition: all 0.35s ease-in-out;
  -moz-transition: all 0.35s ease-in-out;
  transition: all 0.35s ease-in-out;
}
.ih-item.square.effect10 .info h3 {
  text-transform: uppercase;
  color: #fff;
  text-align: center;
  font-size: 17px;
  padding: 10px;
  margin: 0 0 0 0;
}

.ih-item.square.effect10 .info p {
  font-style: italic;
  font-size: 12px;
  position: relative;
  color: #bbb;
  padding: 20px 20px 20px;
  text-align: center;
}

.ih-item.square.effect10 .info .mission-card-title {
    padding: 5px 5px;
}
.ih-item.square.effect10 a:hover .img {
  visibility: visible;
  opacity: 1;
}
.ih-item.square.effect10.bottom_to_top .info {
  -webkit-transform: translateY(0);
  -moz-transform: translateY(0);
  -ms-transform: translateY(0);
  -o-transform: translateY(0);
  transform: translateY(0);
}
.ih-item.square.effect10.bottom_to_top .img {
  -webkit-transform: translateY(100%);
  -moz-transform: translateY(100%);
  -ms-transform: translateY(100%);
  -o-transform: translateY(100%);
  transform: translateY(100%);
}
.ih-item.square.effect10.bottom_to_top a:hover .info {
  -webkit-transform: translateY(-100%);
  -moz-transform: translateY(-100%);
  -ms-transform: translateY(-100%);
  -o-transform: translateY(-100%);
  transform: translateY(-100%);
}
.ih-item.square.effect10.bottom_to_top a:hover .img {
  -webkit-transform: translateY(0);
  -moz-transform: translateY(0);
  -ms-transform: translateY(0);
  -o-transform: translateY(0);
  transform: translateY(0);
}

/*** background ***/
.tsfi-background {
    background: url('https://static.tsfi.org/images/bg.jpg');
    opacity: 0.5;
}
.tsfi-background2 {
    background: url('https://static.tsfi.org/images/bg.jpg') no-repeat center center fixed;
    background-size:cover;
    -o-background-size: cover;
    webkit-background-size:cover;
    moz-background-size:cover;
}


@media (min-width: 576px) {
    .tsfi-section {
        font-size: 14px;
    }
    #tsfi-description {
        font-size: 16px;
    }
}

@media (min-width: 768px) {
    .tsfi-slogan {
        font-size: 20px;
    }
    .tsfi-section {
        font-size: 16px;
    }
    #tsfi-description {
        font-size: 18px;
        margin-bottom: 25px; 
    }
}

@media (min-width: 992px) {
    .tsfi-slogan {
        font-size: 30px;
    }
    .tsfi-section {
        font-size: 18px;
    }
    .tsfi-heading-title {
        padding: 8px 100px 0 100px;
    }
    .tsfi-heading-title-first {
        padding: 0px 100px 0px 100px;
    }
    .tsfi-heading-desc,
    .tsfi-heading-meta {
        padding-left: 100px;
        padding-right: 100px;
    }
}

@media (min-width: 1200px) {
    .tsfi-slogan {
        font-size: 40px;
        line-height: 69px;
    }
    .tsfi-heading-card h1 {
        font-size: 40px;
    }
    h1, .h1 {
        font-size: 48px;
    }

    h2, .h2 {
        font-size: 32px;
    }

    .h2-center {
        font-size: 32px;
    }

    h3, .h3 {
        font-size: 24px;
    }

    h4, .h4 {
        font-size: 17px;
    }
    .tsfi-heading-title {
        padding: 8px 300px 0 300px;
    }
    .tsfi-heading-title-first {
        padding: 0px 300px 0px 300px;
    }
    .tsfi-heading-desc,
    .tsfi-heading-meta {
        padding-left: 300px;
        padding-right: 300px;
    }
}

@media (min-width: 1600px) {
    .tsfi-heading-title {
        padding: 8px 400px 0 400px;
    }
    .tsfi-heading-title-first {
        padding: 0px 400px 0px 400px;
    }
    .tsfi-heading-desc,
    .tsfi-heading-meta {
        padding-left: 400px;
        padding-right: 400px;
    }
}

/* Bug babel translation */
#fieldset-default[style] {
    height: auto !important;
}

#fieldset-default .field {
    top: 0 !important;
    
}

