/* our custom styles */

@import "tsfi-colors.less";  // global colors
@import "tsfi-pagination.less";
@import "tsfi-expertises.less"; 
@import "tsfi-interventions.less"; 
@import "tsfi-pictos.less";  // svg pictos rendering
@import "tsfi-timeline.less";  
@import "tsfi-toolbar.less";  
@import "tsfi-breadcrumbs.less";
//@import "tsfi-header.less";  // custom plone header
@import "tsfi-navbar.less";  // custom plone navbar
@import "tsfi-blocks.less";  // custom plone navbar
@import "tsfi-heading.less";
@import "tsfi-mission.less";
@import "tsfi-urls.less";
@import "tsfi-bs4wrapper.less";
@import "tsfi-common.less";
@import "tsfi-articles.less";
@import "tsfi-briefs.less";
@import "tsfi-firstpage.less";
@import "tsfi-maps.less";
@import "tsfi-whoarewe.less";
@import "tsfi-bases.less";
@import "tsfi-supportus.less"; 
//@import "tsfi-partners.less"; 
@import "tsfi-partnerships.less"; 
@import "tsfi-testimonials.less"; 
@import "tsfi-donate.less"; 
@import "tsfi-home.less"; 
@import "tsfi-search.less"; 
@import "tsfi-header2.less";
@import "tsfi-footer.less";  // custom plone footer

@import url('https://fonts.googleapis.com/css?family=Raleway:400,600');

@font-face {
    font-family: "Bebas Neue";
    src: url('bebasneue/BebasNeue.otf') format("opentype");
}

@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    src: url('opensans/OpenSans-Regular.ttf');
}


//*// body //*//
body #main-container {
    font-family: "Raleway";
    font-size: 18px;
}
/*
#visual-portal-wrapper
{
    background: url('https://static.tsfi.org/images/bg.jpg') no-repeat center center fixed;
    background-size:cover;
    -o-background-size: cover;
    webkit-background-size:cover;
    moz-background-size:cover;
}
*/
body#tinymce
{
    background: none; 
}

.page{
  background-color: @tsfi-white;
}

.heading-section {
    color: @tsfi-white;
    text-align: center;
    vertical-align: middle;
    background-color: @tsfi-blue;
    padding: 0.5em;
    text-transform: uppercase;
}

h2.base-tsf {
    padding: 0.5em;
    text-transform: uppercase;
}

div.base-tsf {
    background-color:@tsfi-grey;
}

//*// annual reports //*//

div.annual-reporting {
    margin-top: 0.5em;
    background-color: @tsfi-grey;
}

img.tsfi-thumb {
    margin-top:1em;
}

div.annual-year {
    margin-top:0.5em;
    font-size: 42px;
    font-weight: bold;
    color: @tsfi-blue;
}

h4.tsfi-thumb-title {
    color: @tsfi-black;
    text-transform: uppercase;
}

/* TinyMCE templates */

// image-grid-2x2:
.image-grid-2x2 {
    padding: 0;
    margin: 0 0 @grid-gutter-width 0;
    list-style: none;
    .make-row();

    > li {
        .make-xs-column(12);
        .make-sm-column(6);
        margin-top: @grid-gutter-width;

        img {
            max-width: 100%;
            max-height: 100%;
        }
    }
}

// DELETE ME:
.custom-theme-marker {
    display: none;
    position: absolute;
    top: 300px;
    left: 30%;
    margin: auto 0;
    transform: rotate(-20deg);
    background: #00ECFF;
    color: #fff;
    padding: 1em;
    z-index: 100;
}
.section-front-page .custom-theme-marker{
    display: block;
}
#portal-footer-wrapper {
padding:inherit;}


.field-of-intervention-link{
    display: inline-block;
}
object{
    pointer-events: none;
}


.select2-container-multi.select2-orderable .select2-search-choice span, .select2-container-multi.select2-orderable .select2-search-choice {
	height:80px;
}


// jp remove https icon before link
i.link-https:after {
    content: none;
}


//
