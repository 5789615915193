
/***** missions section *****/

.missions-filter {
    margin-top: 10px;
    margin-bottom: 34px;
}

.missions-filter-title {
    font-weight: 900;
}

.missions-filter-square {
    width: 132px;
    height: 136px;
    /*border: 1px solid */
    box-shadow: 0 4px 4px 0 rgba(0,0,0,0.20);
    border-radius: 4px;
    word-wrap: normal;
    margin: 10px;
}

.missions-filter-square figure {
    padding-top: 20px;
}

.missions-filter-square object {
    width: 36px;
}

.missions-filter-square span {
    padding-top: 14px;
    font-size: 14px;
    color: @tsf-standalone-link;
    font-weight: 600;
    letter-spacing: -0.26px;
    text-align: center;
    line-height: 16px;
}

a.missions-filter-square {
    text-decoration: none;
}

a.missions-filter-square:hover,
a.missions-filter-square:focus {
    text-decoration: none;
    filter: brightness(95%);
}

#missions-list-title {
    margin-top: 50px;
}

#missions-list-content {
    margin-top: 50px;
    margin-bottom: 50px;

}

/***** mission card list *****/

.card-mission-list {
    margin-bottom: 30px; 
    width:267px;
    background: #FFFFFF;
    border: 1px solid #DFDFDF !important;
    border-radius: 4px !important;
}

.card-mission-list img {
    border-radius: 4px 4px 0 0;
    width:265px;
    height:160px;

}

.card-mission-list .card-body {
    margin-left: 18px;
    margin-right: 18px;
}

.card-mission-list .card-body-date {
    color: #555555;
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 9px;
}

.card-mission-list .card-body-countries {
    color: #FFFFFF;
}
.card-mission-list .card-body-countries span {
    color: #FFFFFF;
    font-size: 14px;
    font-weight: 600;
    line-height: 18px;
    padding: 5px;
    margin-bottom: 14px;
}

.card-mission-list .card-body-title {
    color: #212121;
    font-size: 17px;
    line-height: 22px;
    margin-bottom: 10px;

}

.card-mission-list .card-body-description {
    color: #555555;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: -0.3px;
}


/***** mission page - content - text and boxes *****/

/***** mission page - heading *****/

/* tsfi-common.less */

/***** mission page - content text *****/

/***** mission page - content boxes *****/

#mission-datasheet {
    color: white;
    margin-top: 10px;
    margin-bottom: 10px;
    border-radius: 5px;
    padding-bottom: 10px;
}

#mission-datasheet h2 {
    text-align: left;
}

#mission-datasheet ul {
    margin-bottom: 10px;
    padding-left: 20px;
    padding-left: 20px;
    padding-right: 20px;
}

#mission-datasheet a,
#mission-datasheet a:focus,
#mission-datasheet a:hover {
    color: white;
    text-decoration: none;
    border-bottom: 0;
}



#mission-keyfigures {
    background-color: @tsf-light-grey;
    color: @tsf-black;
    margin-top: 10px;
    margin-bottom: 10px;
    //border: 2px solid @tsf-light-grey;
    border-radius: 5px;
    padding-bottom: 10px;
}

#mission-keyfigures a,
#mission-keyfigures a:focus,
#mission-keyfigures a:hover {
    color: @tsf-black;
    text-decoration: none;
    border-bottom: 0;
}


#mission-keyfigures b {
    font-size: 16px;
}

#mission-gallery {
    background-color: @tsf-light-grey;
    margin-top: 10px;
    margin-bottom: 10px;
    border: 2px solid @tsf-light-grey;
    border-radius: 5px;
}

#mission-gallery span {
    color:@tsf-white;
    background: rgba(0,0,0,0.50);
    padding: 2px 11px 2px 11px;
}


#mission-operational-partners {
    background-color: @tsf-light-grey;
    margin-top: 10px;
    margin-bottom: 10px;
    //border: 2px solid @tsf-light-grey;
    border-radius: 5px;
    padding-bottom: 10px;

}

#mission-operational-partners h2 {
    text-align: left;
}

#mission-operational-partners a,
#mission-operational-partners a:focus,
#mission-operational-partners a:hover {
    color: @tsf-black;
    text-decoration: none;
    border-bottom: 0;
}

#mission-operational-partners ul {
    padding-left: 20px;
    color: @tsf-black;
}

#mission-documents {
    background-color: @tsf-light-grey;
    margin-top: 10px;
    margin-bottom: 10px;
    //border: 2px solid @tsf-light-grey;
    border-radius: 5px;
    padding-bottom: 10px;
}

#mission-documents h2 {
    text-align: left;
}

#mission-documents a,
#mission-documents a:focus,
#mission-documents a:hover {
    color: @tsf-black;
    text-decoration: none;
    border-bottom: 0;
}

#mission-documents ul {
    padding-left: 20px;
    color: @tsf-black;
}


/** mission-expertises **/

#mission-expertises {
    background-color: @tsf-light-grey;
    margin-top: 10px;
    margin-bottom: 10px;
    //border: 2px solid @tsf-light-grey;
    border-radius: 5px;
    padding-bottom: 10px;

}

#mission-expertises h2 {
    text-align: left;
}

#mission-expertises a,
#mission-expertises a:focus,
#mission-expertises a:hover {
    color: @tsf-black;
    text-decoration: none;
    border-bottom: 0;
}

#mission-expertises ul {
    padding-left: 20px;
    color: @tsf-black;
}

/** mission-articles **/

#mission-articles {
    background-color: @tsf-light-grey;
}

.plone-modal .plone-modal-content {
    background: rgba(255, 255, 255, 1);
}

/** mission-partners **/

#mission-partners {
    background-color: @tsf-medium-grey;
}

.mission-partners-box {
    margin-right: 15px;
    margin-left: 15px;
    margin-bottom: 30px;
    background-color: @tsf-white;
}

.mission-partners-title {
    text-align: center;
    margin-bottom: 30px;
}

/*
a.fancyzoom-gallery > img {
    height: 64px;
    width: 64px;
    object-fit: contain;
}
*/

@media (min-width: 576px) {
}

@media (min-width: 768px) {
}

@media (max-width: 992px) {
}
@media (min-width: 992px) {
    #mission-content-right {
        font-size: 15px;
        padding-left: 75px;
    }
    .panel-content {
        padding-left: 15px;
    }
}

@media (min-width: 1200px) {

    .panel-title {
    }


    /** mission-articles **/

    #mission-articles {
        padding-top: 50px;
        padding-bottom: 30px
    }
    .mission-articles-title {
        margin-top: 34px;
        
    }
    .mission-articles-list {
        margin-top: 34px;
    //    padding: 0px 230px 0px 230px;
    }
    /** mission-partners **/

    #mission-partners {
        margin-right: -15px;
        margin-left: -15px;
        padding-top: 50px;
        padding-bottom: 20px;
    }

    .mission-partners-box {
        margin-right: auto;
        margin-left: auto;
        width: 1170px;
    }

    .mission-partners-title {
        margin-bottom: 30px;
    }
}
