.mini-picto{
    display: inline-block;
    height: 35px;
}

.select-picto{
    display: inline-block;
    height: 55px;
}
.missions-resume {
    color: @tsfi-white;
    padding: 5px;
}
.missions-resume-all {
    background-color: @tsfi-blue;
}
.missions-resume-disaster-response {
    background-color: @tsfi-red;
}
.missions-resume-capacity-building {
    background-color: @tsfi-light-green;
}
.missions-resume-education {
    background-color: @tsfi-orange;
}
.missions-resume-digital-divide {
    background-color: @tsfi-purple;
}
.missions-resume-protection{
    background-color: @tsfi-dark-green;
}

.mission-popup {
    margin: 3px;
    padding: 5px 10px 5px 10px;
    color: @tsf-white;
}

.mission-popup span {
    color: @tsf-white;
    font-weight: 600;    
}


.mission-title-popup {
    color: @tsf-white;
    font-weight: 600;    
}

.mission-date-popup {
    color: @tsf-white;
    font-weight: 600;    
}

.mission-popup-disaster-response {
    background-color: @tsfi-red;
}
.mission-popup-capacity-building {
    background-color: @tsfi-light-green;
}
.mission-popup-education {
    background-color: @tsfi-orange;
}
.mission-popup-digital-divide {
    background-color: @tsfi-purple;
}
.mission-popup-protection{
    background-color: @tsfi-dark-green;
}

.more_map_link {
    color: @tsfi-white;
    float:right;
}
