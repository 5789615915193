/***** article *****/

.article-content {
}

#article-content-right {
    background-color: @tsf-light-grey;
    border-radius: 4px;
}

/***** article card list *****/

.card-article-row {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important;
}

.card-article-list {
    margin-bottom: 10px;
    margin-left: 5px;
    margin-right: 5px;
    max-width:364px;
    max-height: 346px;
    background: #FFFFFF;
    border: 1px solid #DFDFDF !important;
    border-radius: 4px !important;
}

.card-article-list img {
    border-radius: 4px 4px 0 0;
    max-width: 100%;
    height: auto;
    max-height: 180px;
}

.card-article-list .card-body {
    margin-left: 18px;
    margin-right: 18px;
}

.card-article-list .card-body-date{
    color: #555555;
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 9px;
}

.card-article-list .card-body-countries span {
    color: #FFFFFF;
    font-size: 14px;
    font-weight: 600;
    line-height: 18px;
    padding: 5px;
    margin-bottom: 14px;
}

.card-article-list .card-body-title {
    color: #212121;
    font-size: 17px;
    line-height: 22px;
    margin-bottom: 10px;

}

.card-article-list .card-body-description {
    color: #555555;
    font-size: 16px;
    line-height: 22px;
}

@media (min-width: 576px) {
}

@media (min-width: 768px) {
}

@media (min-width: 992px) {
    .card-article-list {
        margin: 12px 12px 12px 12px;
    }

    #article-content-left {
        padding-right: 50px;
    }

}

@media (min-width: 1200px) {
    .card-article-row {
        -ms-flex-pack: distribute !important;
        justify-content: normal !important;
    }

}
