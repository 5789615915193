@media (min-width: 576px) {
}

@media (min-width: 768px) {
}

@media (min-width: 992px) {
}

@media (min-width: 1200px) {
}

#timeline-content p {
    text-align: justify;
    text-justify: inter-word;
}

/* vertical ruler */
.timeline {
    position: relative;
    max-width: 1200px;
    margin: 0 auto;
}
.timeline::after {
    content: '';
    position: absolute;
    width: 6px;
    background-color: red;
    top: 0;
    bottom: 0;
    left: 50%;
    margin-left: -3px;
}



/* Container around content */
.container-timeline {
    padding: 10px 40px;
    position: relative;
    background-color: inherit;
    width: 50%;
}

/* Place the container-timeline to the left */
.timeline-left {
    left: -4px;
}

/* Place the container-timeline to the right */
.timeline-right {
    left: 50.15%;
}

/* Add arrows to the left container-timeline (pointing right) */
.timeline-left::before {
    content: " ";
    height: 0;
    position: absolute;
    top: 10px;
    width: 0;
    z-index: 1;
    right: 10px;
    border: medium solid #0094be;
    border-width: 30px 0 30px 30px;
    border-color: transparent transparent transparent #0094be;
}

/* Add arrows to the right container-timeline (pointing left) */
.timeline-right::before {
    content: " ";
    height: 0;
    position: absolute;
    top: 10px;
    width: 0;
    z-index: 1;
    left: 10px;
    border: medium solid #0094be;
    border-width: 30px 30px 30px 0;
    border-color: transparent #0094be transparent transparent;
}

/* Fix the circle for container-timelines on the right side */
.timeline-right::after {
    left: -16px;
}

/* The actual content */
.content {
    padding: 20px 30px;
    background-color: white;
    position: relative;
    border-radius: 6px;
}

/* Media queries - Responsive timeline on screens less than 600px wide */
@media screen and (max-width: 600px) {
  /* Place the timelime to the left */
  .timeline::after {
    left: 31px;
  }

  /* Full-width container-timelines */
  .container-timeline {
    width: 100%;
    padding-left: 70px;
    padding-right: 25px;
  }

  /* Make sure that all arrows are pointing leftwards */
  .container-timeline::before {
    left: 60px;
    border: medium solid white;
    border-width: 10px 10px 10px 0;
    border-color: transparent white transparent transparent;
  }

  /* Make sure all circles are at the same spot */
  .timeline-left::after, .right::after {
    left: 15px;
  }

  /* Make all right container-timelines behave like the left ones */
  .timeline-right {
    left: 0%;
  }
}


.timeline-topheading {
    border-width: 1px;
    border-style: solid;
    background-color: #0094be;
    text-align: center;
}

.timeline-title {
    color: white;
    font-size: 22px;
}

.timeline-date {
    background-color: #e9e3da;
    font-size: 24px;
}

.timeline-subheading {
    border-width: 1px;
    border-style: solid;
    background-color: #222323;
    color: white;
}


/* Collapse */

.collapse {
  display: none;
}
.collapse.in {
  display: block;
}
tr.collapse.in {
  display: table-row;
}
tbody.collapse.in {
  display: table-row-group;
}
.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  -webkit-transition-timing-function: ease;
       -o-transition-timing-function: ease;
          transition-timing-function: ease;
  -webkit-transition-duration: .35s;
       -o-transition-duration: .35s;
          transition-duration: .35s;
  -webkit-transition-property: height, visibility;
       -o-transition-property: height, visibility;
          transition-property: height, visibility;
}
.panel > .list-group,
.panel > .panel-collapse > .list-group {
  margin-bottom: 0;
}
.panel > .list-group .list-group-item,
.panel > .panel-collapse > .list-group .list-group-item {
  border-width: 1px 0;
  border-radius: 0;
}
.panel > .list-group:first-child .list-group-item:first-child,
.panel > .panel-collapse > .list-group:first-child .list-group-item:first-child {
  border-top: 0;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}
.panel > .list-group:last-child .list-group-item:last-child,
.panel > .panel-collapse > .list-group:last-child .list-group-item:last-child {
  border-bottom: 0;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
}
.panel > .panel-heading + .panel-collapse > .list-group .list-group-item:first-child {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.panel-heading + .list-group .list-group-item:first-child {
  border-top-width: 0;
}
.list-group + .panel-footer {
  border-top-width: 0;
}
.panel > .table,
.panel > .table-responsive > .table,
.panel > .panel-collapse > .table {
  margin-bottom: 0;
}
.panel > .table caption,
.panel > .table-responsive > .table caption,
.panel > .panel-collapse > .table caption {
  padding-right: 15px;
  padding-left: 15px;
}
/*
.panel-group .panel-heading + .panel-collapse > .panel-body,
.panel-group .panel-heading + .panel-collapse > .list-group {
  border-top: 1px solid #ddd;
}
.panel-group .panel-footer {
  border-top: 0;
}
.panel-group .panel-footer + .panel-collapse .panel-body {
  border-bottom: 1px solid #ddd;
}
.panel-default {
  border-color: #ddd;
}
.panel-default > .panel-heading {
  color: #333;
  background-color: #f5f5f5;
  border-color: #ddd;
}
.panel-default > .panel-heading + .panel-collapse > .panel-body {
  border-top-color: #ddd;
}
.panel-default > .panel-heading .badge {
  color: #f5f5f5;
  background-color: #333;
}
.panel-default > .panel-footer + .panel-collapse > .panel-body {
  border-bottom-color: #ddd;
}

.panel-primary > .panel-heading + .panel-collapse > .panel-body {
  border-top-color: #337ab7;
}

.panel-primary > .panel-footer + .panel-collapse > .panel-body {
  border-bottom-color: #337ab7;
}

.panel-success > .panel-heading + .panel-collapse > .panel-body {
  border-top-color: #d6e9c6;
}

.panel-success > .panel-footer + .panel-collapse > .panel-body {
  border-bottom-color: #d6e9c6;
}

.panel-info > .panel-heading + .panel-collapse > .panel-body {
  border-top-color: #bce8f1;
}

.panel-info > .panel-footer + .panel-collapse > .panel-body {
  border-bottom-color: #bce8f1;
}

.navbar-collapse:before,
.navbar-collapse:after,
.pager:before,
.pager:after,
.panel-body:before,
.panel-body:after,
.modal-header:before,
.modal-header:after,
.modal-footer:before,
.modal-footer:after {
  display: table;
  content: " ";
}
*/

/*** timeline ***/
.timeline-row-even {
    background-color: #218fbe;
    color: white;
    text-align: left;
}
.timeline-row-odd {
    background-color: white;
    text-align: right;
}
