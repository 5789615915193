#portal-searchbox h4.title a {
    font-family: "Raleway";
    color: @tsf-black;
    font-size: 16px !important;
    text-align: left;
    font-weight: 600;
    text-decoration: none;
}

#portal-searchbox p.description {
    font-family: "Raleway";
    color: @tsf-light-black;
    font-size: 14px !important;
    text-align: left;
}

.livesearch-results {
    text-align: left;
}

.livesearch-results li {
    border-bottom: 1px solid @tsf-blue;
}
