/* breadcrumbs */

#portal-breadcrumbs {
    margin-bottom: 0;
    background-color: @tsf-white;
    box-shadow: 0 3px 4px 0 rgba(11,11,11,0.20);
}
#portal-breadcrumbs a {
    color: @tsf-standalone-link;
}

.plone-breadcrumb {
    ol {
        margin: 0;
        padding: 5px 0;
    }

    li {
        display: inline-block;
        color: @tsf-standalone-link;
        /* crop text after 260px */
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        max-width: 260px;
    }
    
    li + li:before {
        content: '\f054';
        display: inline-block;
        font-family: 'fontAwesome';
        padding: 0 10px;
    }
}

.fa-breadcrumbs {
    color: @tsf-standalone-link;
}

//not breadcrumbs on homepage
body.section-home,
body.section-accueil {  
    #portal-breadcrumbs {
        display: none !important;
    }
}

