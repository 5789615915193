
//*// MENUS //*//

.plone-navbar {
  min-height: 50px;
  margin-bottom: 0px;
  box-shadow:none;
  background-color: @tsfi-menu-bg;
}


.navbar-nav > li > a{
    text-transform: uppercase;
    font-size: inherit;

    @media (max-width: 768px) {
        z-index:1;
    }
    @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait){
    font-size: 88%;
    padding: 5px;
    }
    @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape){
    font-size: 90%;
    padding: 10px;
    }
}


.navbar-nav {
    margin: 5px -15px;
}

.plone-navbar-nav li .submenu {
    background-color: @tsfi-menu-bg;
   /*
    display: none;
    list-style: none;
    padding-left: 0;
    padding-bottom: 0;
    position: absolute;
    z-index: 9999;
    */
    @media (max-width: 768px) {
        z-index:1;
    }
}

