.contact-us {
    margin-top: 50px;
}

.contact-us a {
    color: @tsf-standalone-link;
    text-decoration: underline;
}

.contact-us-base {
    background: #FFFFFF;
    border: 1px solid #DFDFDF;
    box-shadow: 0 4px 4px 0 rgba(0,0,0,0.20);
    border-radius: 4px;
    padding: 10px;
    margin: 10px;
    width: 372px;
    height: 228px;
}


@media (min-width: 576px) {
}

@media (min-width: 768px) {
}

@media (min-width: 992px) {
}

@media (min-width: 1200px) {
}
