#ambassadors-testimonials {
    padding-top: 50px;
    padding-bottom: 50px;
    background-color: @tsf-medium-grey;
}

#ambassadors-testimonials img {
    margin-top: 20px;
    width: 132px;
}

#ambassadors-testimonials .card-body-name span {
    font-size: 14px;
    font-weight: 600;
    line-height: 18px;
    padding: 5px;
    margin-top: 20px;
    margin-bottom: 14px;
    background-color:@tsfi-blue;
    color:@tsfi-white;
}

#ambassadors-testimonials .card-body-desc {
    color: #212121;
    font-size: 17px;
    line-height: 22px;
    margin-bottom: 10px;

}

.card-testimonial {
    margin-bottom: 25px;
    padding: 20px;
}

.card-testimonial img {
    margin-top: 15px;
}

.testimonial-identity {
    margin-top: 15px;
    text-align: center;
}

.testimonial-name {
    font-size: 24px;
    font-weight: 600;
}

.testimonial-member {
    font-size: 24px;
}

.testimonial-content {
    text-align: center;
    margin-top: 20px;
}

@media (min-width: 992px) {
    .testimonial-identity {
        text-align: left;
    }

    .testimonial-content {
        text-align: left;
        padding: 20px 30px 30px 0px;
    }
}
