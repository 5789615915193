.pat-autotoc.autotabs, .autotabs {
    padding: 0 !important;
}

.pat-autotoc.autotabs .currencies-3 i.link-https::after {
    content: none !important;
}

.pat-autotoc.autotabs .currencies-3 i.glyphicon,
.pat-autotoc.autotabs .currencies-3 glyphicon {
    padding: 0;
    margin: 0;
    top: 0;
    display: none;
    line-height: 0;
}


.pat-autotoc.autotabs .amount-4 i.link-https::after {
    content: none !important;
}

.pat-autotoc.autotabs .amount-4 i.glyphicon,
.pat-autotoc.autotabs .amount-4 glyphicon {
    padding: 0;
    margin: 0;
    top: 0;
    display: none;
    line-height: 0;
}

.donate-row-card {
    -ms-flex-pack: center !important;
    justify-content: center !important;
}

@media (min-width: 992px) {
    .donate-row-card {
        -ms-flex-pack: justify !important;
        justify-content: space-between !important;
    }
}

/*** devise **/

#tsfi-donate .h4 {
    margin-left: 20px;
}

.currencies-card {
    border-color: @tsfi-blue;
    background-color:@tsf-light-grey;
    margin-bottom: 30px;
}

.currencies-card a {
    margin: 3px;
    background-color:@tsfi-lightgrey-card;
    color: @tsfi-black;
    padding: 15px 5px 15px 5px;
    font-weight: 600;
    text-align: center;
    width: 100px;
}

.currencies-card a.active {
    background-color:@tsfi-blue;
    color:@tsfi-white;
}

.donate-card-1 {
    background-color: @tsf-light-grey;
    text-align: center;
    padding-top: 34px;
    padding-bottom: 34px;
}

.donate-card-2 {
    text-align: center;
    padding-top: 34px;
    padding-bottom: 34px;
}

.donate-card-3 {
    background-color: @tsf-light-grey;
    text-align: center;
    padding-top: 34px;
    padding-bottom: 34px;
}

.donate-card-1 h3,
.donate-card-2 h3,
.donate-card-3 h3 {
    padding-top: 0;
}

.pat-autotoc.autotabs, .autotabs {
    border: 0px;
    border-color:@tsfi-white;
}

#tsfi-donate h2 {
    margin-left: 20px;
}



/*** Montant ***/

.tsfi-autotabs-lvl1 .h4 {
    margin-left: 10px !important;
}

.amount-card {
    border-color: @tsfi-blue;
    margin: 10px;
}

.amount-card a {
    margin: 3px;
    background-color:@tsfi-lightgrey-card;
    color: @tsfi-black;
    padding: 15px 5px 15px 5px;
    font-weight: 600;
    height: 134px;
    width: 170px;
}

.amount-card a.active {
    background-color:@tsfi-blue;
    color:@tsfi-white;
}

field-amount {
    display:none;
}

/*** card photo/description ***/
#tsfi-donate .description {
   margin: 0 0 10px 15px;
}

#tsfi-donate .description-card {
    color:@tsfi-white;
    background-color:@tsfi-blue;
    margin-left: 13px;
    margin-right: -15px;
}

#tsfi-donate .content-donation {
    padding: 20px;
    text-align: left;
}

.donate-card-title {
    margin-left: 10px !important;
}
