/* phase 2 : filter */

.expertise-filter {
    margin-top: 10px;
    margin-bottom: 34px;
}

.expertise-filter-title {
    font-weight: 900;
}

.expertise-filter-square {
    width: 118px;
    height: 136px;
    /*border: 1px solid */
    box-shadow: 0 4px 4px 0 rgba(0,0,0,0.20);
    border-radius: 4px;
    word-wrap: normal;
    margin: 10px
}

.expertise-filter-square figure {
    padding-top: 20px;
}

.expertise-filter-square object {
    width: 36px;
}

.expertise-filter-square span {
    padding-top: 14px;
    font-size: 14px;
    color: @tsf-standalone-link;
    font-weight: 600;
    letter-spacing: -0.26px;
    text-align: center;
    line-height: 16px;
}

a.expertise-filter-square {
    text-decoration: none;
}

a.expertise-filter-square:hover,
a.expertise-filter-square:focus {
    text-decoration: none;
    filter: brightness(95%);
}

/* phase 2 : card */

.solution-card {
    width: 250px;
    margin-top:10px;
    color: @tsfi-black;
    border: 1px solid @tsfi-blue; 
    border-radius: 4px !important;
    text-align: left;
    box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.3);
}

.solution-card .card-text {
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: 16px;
    letter-spacing: -0.3px;
    line-height: 22px;
}

.solution-card .card-footer {
    border-top: 0px;
    margin-top: 10px;
    background-color: @tsf-white;
}

.solution-card .card-footer a {
    color: @tsf-standalone-link;
    text-decoration: underline;
    font-size: 14px;
    line-height: 27px;
}

/*
.cell-foe{
    height:130px;
}


.expertise-filter {
    width: 115px;
}
.expertise-filter object {
    width: 35px;   
}
.expertise-filter span {
    font-size: 10px;
}
*/

/*** solution.pt ***/

#techno-content-right {
    margin-bottom: 50px;
}

#techno-content-left {
}

#techno-datasheet {
    background-color: @tsfi-blue;
    color: white;
    margin-top: 10px;
    margin-bottom: 10px;
    border: 2px solid @tsfi-blue;
    border-radius: 5px;
    padding-bottom: 10px;
}

#techno-datasheet h2 {
    text-align: left;
}

#techno-datasheet a,
#techno-datasheet a:focus,
#techno-datasheet a:hover {
    color: white;
    text-decoration: none;
    border-bottom: 0;
}

#techno-datasheet ul {
    margin-bottom: 10px;
    padding-left: 20px;
}

#techno-contributors {
    background-color: @tsf-light-grey;
    margin-top: 10px;
    margin-bottom: 10px;
    //border: 2px solid @tsf-light-grey;
    border-radius: 5px;
    padding-bottom: 10px;

}

#techno-contributors h2 {
    text-align: left;
}

#techno-contributors a,
#techno-contributors a:focus,
#techno-contributors a:hover {
    color: @tsf-black;
    text-decoration: none;
    border-bottom: 0;
}

#techno-contributors ul {
    padding-left: 20px;
    color: @tsf-black;
}


#techno-documents {
    background-color: @tsf-light-grey;
    margin-top: 10px;
    margin-bottom: 10px;
    border-radius: 5px;
    padding-bottom: 10px;

}

#techno-documents h2 {
    text-align: left;
}

#techno-documents a,
#techno-documents a:focus,
#techno-documents a:hover {
    color: @tsf-black;
    text-decoration: none;
    border-bottom: 0;
}

#techno-documents ul {
    padding-left: 20px;
    color: @tsf-black;
}

#techno-interventions {
    background-color: @tsf-light-grey;
    margin-top: 10px;
    margin-bottom: 10px;
    border-radius: 5px;
    padding-bottom: 10px;
}

.techno-interventions-title {
    margin-top: 10px;
    margin-bottom: 15px;
}

#techno-articles {
    background-color: @tsf-light-grey;
}

/*** /solution.pt ***/
@media (min-width: 576px) {
    .solution-card {
        width: 300px;
        margin-top: 25px;
    }
}

@media (min-width: 768px) {

}

@media (max-width: 992px) {
}

@media (min-width: 992px) {
    #techno-content-right {
        font-size: 16px;
        padding-left: 75px;
    }
}

@media (min-width: 1200px) {
    .expertise-filter object {
        width: 36px;   
    }
    .expertise-filter span {
        font-size: 14px;
    }
}
