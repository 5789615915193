/*** tsfi heading ***/
#home-header .tsfi-slogan {
    color: @tsf-white;
    background: rgba(0,0,0,0.50);
    padding: 4px 15px 4px 15px;
    border-radius: 25px;
    margin: 20px;
    max-width: 300px;
    width: 300px;
}
#home-header .tsfi-slogan2 {
    color: @tsf-white;
    background: rgba(0,157,222,0.50);
    padding: 4px 15px 4px 15px;
    border-radius: 25px;
    margin: 20px;
    max-width: 300px;
    width: 300px;
}
#home-header .tsfi-slogan-home {
    color: @tsf-white;
    padding: 4px 15px 4px 15px;
    border-radius: 25px;
    margin: 20px;
    max-width: 600px;
}

#home-header .tsfi-slogan-countries {
    font-family: "Bebas Neue";
    color: @tsf-white;
    font-size: 20px;
    font-weight: 400;
}

#home-header .tsfi-slogan-title {
    font-family: "Raleway";
    font-size: 15px;
    color: @tsf-white;
    text-align: right;
}

#home-header .tsfi-slogan-responsive {
    background-color: @tsf-black;
    padding: 5px;
}

#home-header .tsfi-slogan2-responsive {
    background: rgba(0,157,222,1);
    color: @tsf-white;
    padding: 5px;
}

#home-header .tsfi-slogan-responsive span {
    font-family: "Bebas Neue";
    color: @tsf-white;
}

/*** Article à la une ***/

#home-news .home-twitter-block h2,
#home-news .home-twitter-block iframe {
    display: none !important;
}

#home-news .home-social-block {
    text-align: center;
}

#home-news h2 {
    margin-top: 44px;
    margin-bottom: 20px;
    text-align: left;
}

#home-news a.readmore span {
    margin-top: 20px;
    margin-bottom: 30px;

}

#home-news .home-social-block {
    margin-top: 20px;
}

#home-news .home-social-block .home-social-facebook {
    color: #3b5998;
    text-decoration: none;
}

#home-news .home-social-block .home-social-twitter {
    color: #00acee;
    text-decoration: none;
}

#home-news .home-social-block .home-social-linkedin {
    color: #0e76a8;
    text-decoration: none;
}

#home-news .home-social-block .home-social-youtube {
    color: #c4302b;
    text-decoration: none;
}

#home-news .fa-instagram-square:before {
    content: "\f16d";
}

#home-news .fa-instagram-square {
    border-radius: 5px;
    background: #d6249f;
    background: radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%,#d6249f 60%,#285AEB 90%);
    box-shadow: 0px 3px 10px rgba(0,0,0,.25);
    color: white;
    padding: 0 2px 0 2px;
    font-size: 31px;
}

#home-news .home-social-instagram {
    color: #c4302b;
    text-decoration: none;
}

/*** Nos missions en cours ***/

#home-map h2 {
    margin-top: 44px;
    margin-bottom: 20px;
}

/*** Mission à la une ***/

#home-missions {
    background: #EAEAEA;
}
#home-missions h2 {
    margin-top: 44px;
    margin-bottom: 20px;
}

#home-missions a.readmore span {
    margin-top: 20px;
    margin-bottom: 30px;

}

/*** Nos types d'actions ***/
#home-actions {
    background-color: @tsf-white;
    padding-bottom: 50px;
}

#home-actions h2 {
    margin-top: 44px;
    margin-bottom: 20px;
}

/*** Depuis 1998 ***/


#home-keyfigures {
    background-color: @tsf-light-grey;
    padding-bottom: 50px;
}

.home-keyfigures-title {
    margin-top: 44px;
    margin-bottom: 20px;
}

.home-keyfigures-square {
    width: 275px;
    height: 259px;
    box-shadow: 0 4px 4px 0 rgba(0,0,0,0.20);
    border-radius: 4px;
    word-wrap: normal;
    margin: 10px 10px 10px 10px;
}


.home-keyfigures-square img {
    padding-top: 36px;
    width: 92px;
}

.home-keyfigures-square .card-text-highlight {
    padding-top: 14px;
    font-size: 32px;
    color: @tsf-black;
    text-align: center;
    line-height: 30px;
    font-weight: 600;
    margin: 0;
}

.home-keyfigures-square p {
    padding-top: 14px;
    font-size: 26px;
    color: @tsf-grey;
    text-align: center;
    line-height: 30px;
    
}


/*** Who are we ***/
#home-whoarewe {
    background-color: @tsf-white;
    padding-bottom: 50px;
}

.home-whoarewe-title {
    margin-top: 44px;
    margin-bottom: 20px;
}




@media (min-width: 576px) {
}

@media (min-width: 768px) {
    #home-header .tsfi-slogan {
        padding: 4px 15px 4px 15px;
        border-radius: 25px;
        margin: 20px;
        max-width: 300px;
        width: 300px;
    }
    #home-header .tsfi-slogan2 {
        padding: 4px 15px 4px 15px;
        border-radius: 25px;
        margin: 20px;
        max-width: 300px;
        width: 300px;
    }

    #home-header .tsfi-slogan-countries {
        font-size: 20px;
    }

    #home-header .tsfi-slogan-title {
        font-size: 15px;
        text-align: right;
    }
}

@media (min-width: 992px) {
    #home-header .tsfi-slogan {
        padding: 4px 15px 4px 15px;
        border-radius: 25px;
        margin: 30px;
        max-width: 400px;
        width: 400px;
    }
    #home-header .tsfi-slogan2 {
        padding: 4px 15px 4px 15px;
        border-radius: 25px;
        margin: 30px;
        max-width: 400px;
        width: 400px;
    }

    #home-header .tsfi-slogan-countries {
        font-size: 30px;
    }

    #home-header .tsfi-slogan-title {
        font-size: 20px;
        text-align: right;
    }
}

@media (min-width: 1200px) {
    #home-header .tsfi-slogan {
        padding: 4px 15px 4px 15px;
        border-radius: 25px;
        margin: 30px;
        max-width: 700px;
        width: 700px;
    }
    #home-header .tsfi-slogan2 {
        padding: 4px 15px 4px 15px;
        border-radius: 25px;
        margin: 30px;
        max-width: 700px;
        width: 700px;
    }

    #home-header .tsfi-slogan-countries {
        font-size: 50px;
    }

    #home-header .tsfi-slogan-title {
        font-size: 30px;
        text-align: right;
    }

    #home-news .home-twitter-block h2,
    #home-news .home-twitter-block iframe {
        display: block !important;
    }

    #home-news .home-social-block {
        text-align: left;
        margin-top: 20px;
    }
}

@media (min-width: 1600px) {
    #home-header .tsfi-slogan {
        padding: 4px 15px 4px 15px;
        border-radius: 25px;
        margin: 30px;
        max-width: 700px;
    }
    #home-header .tsfi-slogan2 {
        padding: 4px 15px 4px 15px;
        border-radius: 25px;
        margin: 30px;
        max-width: 700px;
        width: 700px;
    }

    #home-header .tsfi-slogan-countries {
        font-size: 80px;
    }

    #home-header .tsfi-slogan-title {
        font-size: 50px;
        text-align: right;
    }
}
