
@picto-card-size: 40px;
@picto-xxs-size: 50px;
@picto-xs-size: 65px;
@picto-sm-size: 80px;
@picto-md-size: 90px;
@picto-lg-size: 120px;
@picto-madebytsfi-size: 50px;

@picto-lg-width: @picto-lg-size;
@picto-lg-height: @picto-lg-width;
@picto-md-width: @picto-md-size;
@picto-md-height: @picto-md-width;
@picto-sm-width: @picto-sm-size;
@picto-sm-height: @picto-sm-width;
@picto-xs-width: @picto-xs-size;
@picto-xs-height: @picto-xs-width;


.relation {
   display:inline;
}

.techno-intervention-picto {
  width: @picto-xxs-size;
   display:inline;
}

.relation-picto {
  width: @picto-xs-size;
   display:inline;
}

.card-picto {
  width: @picto-card-size;
  padding:5px;
}

.madebytsfi-picto {
  width: @picto-madebytsfi-size;
  padding-left: 10px;
}


.title-picto {
	height:@picto-xs-size;
	margin:10px;
}

a object{
	pointer-events: none;
}	

//*// TSFI ACTIONS //*//


@media(max-width:767px){
    &.picto {
        width: @picto-xs-width;
    	height: @picto-xs-height;
    }

}
@media(min-width:768px){
    &.picto {
        width: @picto-sm-width;
    	height: @picto-sm-height;
    }

}
@media(min-width:992px){
    &.picto {
        width: @picto-md-width;
    	height: @picto-md-height;
    }

}
@media(min-width:1200px){
    &.picto {
        width: @picto-lg-width;
    	height: @picto-lg-height;
    }
    &.row-actions {
	margin-top: 2em;
    }
}


