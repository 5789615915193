
//*// footer //*//

footer {
    color: @tsfi-grey;
}

.footer-row1 {
    background-color: @tsf-footer1-background;
    padding: 18px 0 18px 0;
}

.footer-row1 .footer-newsletter {
    padding: 10px;
    text-align: center;
}

.footer-row1 .footer-followus {
    padding: 10px;
    text-align: center;
}

.footer-row1 .footer-text {
    color: @tsf-white;
    font-size: 17px;
    font-weight: 400;
    margin-right: 17px;

}

.footer-row1 .fa-footer {
    color: @tsf-white;
}

.footer-row1 #mce-EMAIL {
    width: 280px;
    color: @tsf-footer2-color;
}

.footer-row2 {
    background-color: @tsf-footer2-background;
    padding-top: 30px;
    text-align: center;
}

.footer-row2 .fa-footer {
    color: @tsf-white;
}

.footer-social {
    color: @tsf-white;
    margin: 5px;
}

.followus {
    text-align: center;
    margin-top: 5px;
}


.footer-row2 .footer-menu {
    text-align: center;
    margin-top: 11px;
}

.footer-row2 a:hover,
.footer-row2 a:focus {
    color: inherit;
    text-decoration: none;
    filter: brightness(95%);
}


.footer-visibility {
    margin-bottom: 30px;
}


#footer-logo img {
    width: 230px;
}

.footer-partners {
    background-color: @tsf-standalone-link;
    color: @tsf-white;
    font-family: "Bebas Neue";
    font-size: 24px;
    padding: 3px 13px 1px 13px;
    
}

.footer-donate {
    background-color: @tsf-red;
    color: @tsf-white;
    font-family: "Bebas Neue";
    font-size: 24px;
    padding: 3px 13px 1px 13px;
}

.col-categories {
    margin-bottom: 10px;
}

.footer-title {
    color: @tsf-white;
    font-size: 17px;
    font-weight: 500;
}

.footer-list {
    list-style-type: none;
    margin: 0;
    padding: 10px 0 0 0;
}

.footer-item {
    color: @tsf-footer2-color;
    font-size: 16px;
}


@media (min-width: 576px) {
}

@media (min-width: 768px) {
    .col-categories {
        margin-bottom: 0;
    }

    .footer-row1 .footer-newsletter {
        text-align: left;
    }

    .footer-row1 .footer-followus {
        text-align: left;
    }

}

@media (min-width: 992px) {
    .footer-col {
        margin-left: 10px;
        margin-right: 10px;
        display: inline-block;
        text-align: left;
    }

    .followus {
        text-align: left;
        margin-left: 26px;
    }
}

@media (min-width: 1200px) {
    #footer-logo img {
        margin-top: -20px;
    }

    .footer-visibility {
        margin-bottom: 0;
    }

}
