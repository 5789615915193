.tsfi-rounded img {
    border-radius: 50% !important;
}

#partnerships-content {
    padding-top: 50px;
    padding-bottom: 30px;
    background-color: @tsf-medium-grey;
}

#partnerships-partners {
    padding-top: 50px;
    padding-bottom: 30px;
    background-color: @tsf-white;
}

#partnerships-partners .h2 {
    margin-bottom: 50px;
}

#partnerships-op-partners {
    padding-bottom: 30px;
    background-color: @tsf-white;
}

#partnerships-op-partners .h2 {
    margin-bottom: 50px;
}

#partnerships-testimonials {
    padding-top: 50px;
    padding-bottom: 30px;
    background-color: @tsf-light-grey;
}

#partnerships-testimonials .collapsed .quotation{
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 200px;
}

.partnerships-testimonials-title {
    margin-bottom: 20px;
}
.partnerships-testimonials-intro {
    margin-bottom: 20px;
}
