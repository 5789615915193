
//*// PLONE TOOLBAR //*//
//*// content types font icons //*//

body#visual-portal-wrapper.pat-plone .outer-wrapper {
	.contenttype-activity_report:before {content: '\e811';}
	.contenttype-annual_reports_section:before {content: '\e809';}
	.contenttype-annual_reports:before {content: '\e809';}
	.contenttype-base:before {content: '\e800';}
	.contenttype-bases_section:before {content: '\e804';}
	.contenttype-brief:before {content: '\e80f';}
	.contenttype-tsfi_actions_section:before {content: '\e82c';}
	.contenttype-tsfi_action:before {content: '\e82c';}
	.contenttype-countries_section:before {content: '\e836';}
	.contenttype-country:before {content: '\e836';}
	.contenttype-financial_report:before {content: '\e811';}
	.contenttype-partner:before {content: '\e800';}
	.contenttype-partnership_file:before {content: '\e811';}
	.contenttype-partnership_section:before {content: '\e804';}
	.contenttype-partnership_subsection:before {content: '\e804';}
	.contenttype-tsfi_timeline:before {content: '\e809';}
	.contenttype-tsfi_timeline_event:before {content: '\e809';}
}

//*// on right on toolbar
body#visual-portal-wrapper.pat-plone #edit-zone {
	.contenttype-activity_report:after {content: '\e811';}
	.contenttype-annual_reports_section:after {content: '\e809';}
	.contenttype-annual_reports:after {content: '\e809';}
	.contenttype-base:after {content: '\e800';}
	.contenttype-bases_section:after {content: '\e804';}
	.contenttype-brief:after {content: '\e80f';}
	.contenttype-tsfi_actions_section:after {content: '\e82c';}
	.contenttype-tsfi_action:after {content: '\e82c';}
	.contenttype-countries_section:after {content: '\e836';}
	.contenttype-country:after {content: '\e836';}
	.contenttype-financial_report:after {content: '\e811';}
	.contenttype-partner:after {content: '\e800';}
	.contenttype-partnership_file:after {content: '\e811';}
	.contenttype-partnership_section:after {content: '\e804';}
	.contenttype-partnership_subsection:after {content: '\e804';}
	.contenttype-tsfi_timeline:after {content: '\e809';}
	.contenttype-tsfi_timeline_event:after {content: '\e809';}
}
