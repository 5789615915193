
.technical_data_sheet{
  background-color:@tsfi-red;
  color:@tsfi-white;
  padding: 10px;
}

.techno-contributors{
  margin-top:10px;
  background-color:@tsfi-blue;
  color:@tsfi-white;
  padding: 10px;
}

.techno-contributors-link{
  color:@tsfi-white;
}
